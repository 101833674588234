import { render, staticRenderFns } from "./FeaturedProjectCard.vue?vue&type=template&id=ec722dea&scoped=true&"
import script from "./FeaturedProjectCard.vue?vue&type=script&lang=js&"
export * from "./FeaturedProjectCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec722dea",
  null
  
)

export default component.exports