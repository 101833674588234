<template>
  <div class="navbar" v-if="false">
    <div class="container">
      test
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar"
}
</script>

<style scoped>

</style>